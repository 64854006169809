.mapboxgl-export-list {
  display: none;
  text-align: center;
  font-size: 14px;
}

.mapboxgl-ctrl-group .mapboxgl-export-list button {
  /* background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
  text-align: center; */
}

.mapboxgl-export-list button.active {
  font-weight: bold;
}

.mapboxgl-export-list button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-export-list button + button {
  border-top: 1px solid #ddd;
}

.mapboxgl-export-control {
  background: url('data:image/svg+xml;charset=UTF-8,<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m422.5 99v-24c0-41.355-33.645-75-75-75h-184c-41.355 0-75 33.645-75 75v24z"/><path d="m118.5 319v122 26 15c0 16.568 13.431 30 30 30h214c16.569 0 30-13.432 30-30v-15-26-122zm177 128h-80c-8.284 0-15-6.716-15-15s6.716-15 15-15h80c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-80c-8.284 0-15-6.716-15-15s6.716-15 15-15h80c8.284 0 15 6.716 15 15s-6.716 15-15 15z"/><path d="m436.5 129h-361c-41.355 0-75 33.645-75 75v120c0 41.355 33.645 75 75 75h13v-80h-9c-8.284 0-15-6.716-15-15s6.716-15 15-15h24 304 24c8.284 0 15 6.716 15 15s-6.716 15-15 15h-9v80h14c41.355 0 75-33.645 75-75v-120c0-41.355-33.645-75-75-75zm-309 94h-48c-8.284 0-15-6.716-15-15s6.716-15 15-15h48c8.284 0 15 6.716 15 15s-6.716 15-15 15z"/></g></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

/*
 * Hide high-res map rendering
*/
.hidden-map {
  overflow: hidden;
  height: 0;
  width: 0;
  position: fixed;
}

.print-table {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  caption-side: bottom;
  border-collapse: collapse;
}

.print-table tr td {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  text-align: left;
  vertical-align: top;
  padding: 5px;
}

.print-table tr td:first-child {
  width: 80px;
}

.print-table tr td:nth-child(2) {
  width: 200px;
}

.print-table tr td input[type='text'],
.print-table tr td textarea[type='text'] {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 2px 10px;
}

.print-table tr td textarea[type='text'] {
  height: 100px;
}

.mapboxgl-export-list button[type='button'].generate-button {
  margin-bottom: 15px;
  background-color: #1677ff;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  padding: 4px 15px;
  position: relative;
  outline: none;
  text-align: center;
  white-space: nowrap;
  width: 90%;
  transition: 0.3s;
}
.mapboxgl-export-list button[type='button'].generate-button:hover {
  opacity: 0.7;
}
