@import url('https://fonts.googleapis.com/css?family=Kanit:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic');

body,
*,
.ant-input {
  font-family: IBM Plex Sans Thai;
}
/* .mapboxgl-ctrl-top-left {
  top: 2.5rem !important;
  left: 0;
}

.mapboxgl-ctrl-top-right {
  top: 2.5rem;
  right: 0;
}

.toggle-section-left {
  background-color: #000;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 8%);
  position: absolute;
  top: 0.5rem;
  border-radius: 0.25rem;
  left: calc(100% + 0.5rem);
  z-index: 9999;
  color: white;
  cursor: pointer;
  padding: 4px;
}

.toggle-section-right {
  background-color: #000;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 8%);
  position: absolute;
  top: 0.5rem;
  border-radius: 0.25rem;
  right: calc(100% + 0.5rem);
  z-index: 9999;
  color: white;
  cursor: pointer;
  padding: 4px;
}

.section-header {
  box-shadow: 0 1px 0 0 rgb(255 255 255 / 8%);
  background: #232323;
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 1rem 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: max-width 0.16s ease 0s, padding 0.16s ease 0s, opacity 0.16s ease 0s;
  transition: max-width 0.16s ease 0s, padding 0.16s ease 0s, opacity 0.16s ease 0s;
  overflow: visible;
  max-width: 100vw;
  padding: 1rem;
  opacity: 1;
}

.section-header > h1 {
  color: rgba(255, 255, 255, 0.64);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-feature-settings: 'pnum' 0;
  font-family: 'Barlow', sans-serif;
  font-weight: 200;
  text-transform: uppercase;
  margin: 0;
}

.section-menu {
  color: white;
} */
.header {
  background: white !important;
}
.header-logo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 25%;
}
.header-image {
  width: 60px;
  margin-right: 1rem;
}
.header-logo h1 {
  font-family: IBM Plex Sans Thai;
  font-size: 1.2rem;
  color: #186a7c;
  text-shadow: 2px 2px #ccc;
}
.header-link {
  padding-right: 1rem;
  line-height: 4rem;
}
.header-link a {
  font-family: IBM Plex Sans Thai;
  font-size: 1.2rem;
  color: #186a7c;
  line-height: 5rem;
}

.header-link a:hover {
  color: #2d2d2d;
}

.search-result .ant-table-tbody tr td.ant-table-cell {
  padding: 0.3rem;
}

.control-panel {
  position: absolute;
  top: -10px;
  right: 40px;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}

/* .tooltip {
  position: absolute;
  margin: 8px;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  max-width: 300px;
  font-size: 12pt;
  line-height: 22pt;
  z-index: 9;
  pointer-events: none;
} */
.mobile > div.ant-slider-handle::after {
  box-shadow: 0 0 2px #669966 !important;
  width: 15px !important;
  height: 15px !important;
}
.mobile > div.ant-slider-rail {
  height: 10px !important;
}
/* .mapboxgl-ctrl-zoom-in {
  width: 7vw !important;
  height: 7vw !important;
}
.mapboxgl-ctrl-zoom-out {
  width: 7vw !important;
  height: 7vw !important;
}
.mapboxgl-ctrl-compass {
  width: 7vw !important;
  height: 7vw !important;
} */

.custom-popup > .mapboxgl-popup-content {
  min-width: 230px;
  max-width: 300px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9;
}
